<template>
  <footer class="footer-wrap ">
      <div class="footer-location">
          <iframe id="map" src="https://maps.google.com/maps?q=Slobodana%20Jovi%C4%87a%2020%20&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" style="border:0" allowfullscreen></iframe>
      </div>
      <div class="footer-contact">
        <h4>Kontakt: </h4>
        <span style="color:#fff">Ovlašćeni predstavnik proizvođača i distributer</span>    
        <span>
          <a href="http://celtispharm.rs/"  target="_blank">
            <img  src="../assets/celtispharm.png" alt="celtispharm">
          </a>
        </span>  
        <span style="color:#fff">Celtis Pharm d.o.o. Beograd, Srbija</span>      
        <span>
          <a href="mailto:office@celtispharm.rs"><i class="fas fa-envelope"></i> office@celtispharm.rs</a>
        </span>
        <span>
          <a href="tel:+381114360019"><i class="fas fa-phone-alt"></i> 011 436 0019</a>
        </span>
      </div>

    <div class="btn-holder" :style="[goUpBtn ? {opacity: '1'} : {opacity: '0'}]">
        <button @click="goUp"><i class="fas fa-chevron-up"></i></button>
    </div>
  </footer>
</template>
<script>
export default {
    data() {
        return {
            goUpBtn: false
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    unmounted() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            if (window.scrollY < 100) {
                this.goUpBtn = false;
                return;
            }
            if ((window.innerHeight + window.scrollY) >= 400) {
                this.goUpBtn = true;
            }else {
                this.goUpBtn = false;
            }
        },
        goUp() {
            window.scroll(0, 0);
        }
    }
}
</script>
<style lang="scss" scoped>


  .footer-wrap {
    background: #7395ae url("../assets/navbackground.png") ;
    background-size: 100% 100%;
    min-height: 300px;
    padding: 15px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    transition: .3s;
    text-align: left;

    a, h4 {
      color: #fff;
      margin: 25px 0;
    }

    .btn-holder {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
    div {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      width: 50%;
      height: 300px;
      #map{
        height: 90%;
        width: 90%;
        border-radius: 10px;
      }
    }
    .footer-contact {
      justify-content: flex-start;
      img {
        padding: 5px;
        // background: rgba(255, 255, 255, 0.534);
        height: 25px;
        border-radius: 5px;
      }
    }
  }
  @media screen and (max-width: 800px) {
    .footer-wrap {
      div {
        width: 100%;
      }
    }
  }
</style>