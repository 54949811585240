<template>
  <div class="main-div">
    <div id="nav">
      <TheNav v-if="windowWidth > 1000" />
      <TheNavMobile v-if="windowWidth <= 1000" />
    </div>
    <router-view />
    <TheFooter/>
  </div>
</template>

<script>
import TheNav from "@/components/TheNav.vue";
import TheNavMobile from "@/components/TheNavMobile.vue";
import TheFooter from "@/components/TheFooter.vue";

export default {
  name: "Home",
  data() {
    return {
      windowWidth: window.innerWidth
    }
  },
  components: {
    TheNav,
    TheNavMobile,
    TheFooter,
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    })
  },
  unmounted() {
      window.removeEventListener("resize")
  },
  watch: {
    $route() {
      window.scroll(0, 0);
    }
  }
};
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
  background: #F4F4F4;
}
::-webkit-scrollbar {
  width: 15px;
}
::-webkit-scrollbar-track {
  background: #4b6374;
  border-radius: 1px;
}

::-webkit-scrollbar-thumb {
  background: #3c4f5e;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #4b6374;
  cursor: pointer;
}
#app {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;  
  box-sizing: border-box;

  .main-div{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  button {
    border: 0;
    padding: 0;
    font-family: inherit;
    font-size: 100%;
  }

  a:link,
  a:visited,
  a:active {
    text-decoration: none;
  }

  a:after {
    display: block;
    content: "";
    width: 100%;
    margin: 0 auto;
    border-top: solid 2px #ffffff;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
    /* transform-origin: right; */
  }
  a:hover:after {
    transform: scaleX(1);
    transition: transform 0.3s ease-in-out;
    /* transform-origin: left; */
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.4s;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .logo-img {
    padding: 0 25px;
    height: 55px;
    cursor: pointer;
    transition: .3s;
  }
  .btn-holder{
    padding: 6px;
    border-radius: 60px;
    font-weight: 700;
    width: 35px;
    height: 35px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      background: #fff;
      border-radius: 60px;
      color: #2c3e50;
      cursor: pointer;
      width: 35px;
      height: 35px;
      outline: none;
      transition: .3s;
      &:hover {
        box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.381),
                          1px 1px 2px rgba(0, 0, 0, 0.381);
      }
      &:active {
        box-shadow: inset 1px 1px 8px rgba(0, 0, 0, 0.381),
                          1px 1px 8px rgba(0, 0, 0, 0.381);
      }
    }
  }
  
  .text-info {
    margin: 35px auto 35px;
    width: 50%;
    padding: 25px 40px;
    border-top: 2px solid #7395ae96;
    border-bottom: 2px solid #7395ae96;
    text-align: left;
    p{
      text-align: left;
      // color: #333;
    }
  }

  table {
      border-collapse: collapse;
      margin: 20px 0;
      tr {
        th {
          border: 1px solid #7395ae96; 
          padding: 10px;
          width: 50%;
        }
        td {
          border: 1px solid #7395ae96; 
          padding: 10px;
          width: 50%;
        }
      }
  }
  
  @media screen and (max-width: 800px) {
    .text-info {
      width: 80%;
    }
    table {
      margin: 15px 0;
      tr {
        th {
          padding: 8px;
        }
        td {
          padding: 8px;
        }
      }
  }
  }

  .fade-in-top {
    -webkit-animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  @-webkit-keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.35s ease;
  }

  .fade-enter-from,
  .fade-leave-active {
    opacity: 0;
  }

  .opened-mobile-nav {
    overflow-y: hidden;
  }
}
</style>
