<template>
  <div class="nav-wrap fade-in-top">
    <div @click="goToHome" class="logo-img">
      <img src="../assets/logo.png" alt="logo" />
    </div>
    <div @click="openMobileNav()" :class="showNav ? 'change' : 'burger'">
      <div class="line1"></div>
      <div class="line2"></div>
      <div class="line3"></div>
    </div>
    <nav id="nav-mobile" class="fade-in-top" v-if="showNav">
      <div id="mobile-router-link-container">
        <router-link :to="{name: 'Home'}">{{ $t("nav.homepage") }}</router-link>
        <router-link :to="{name: 'About'}">{{ $t("nav.aboutUs") }}</router-link>
        <router-link :to="{name: 'Products'}">{{ $t("nav.products") }}</router-link>
        <router-link :to="{name: 'ProductUsage'}">{{ $t("nav.productUsage") }}</router-link>


        <div>
          <a @click="galleryDropdown = !galleryDropdown">
            {{ $t("nav.gallery.title") }}
            <i class="fas fa-chevron-down"></i>
          </a>
          <div v-if="galleryDropdown" class="dropdown-mobile fade-in-top">
            <div><router-link :to="{name: 'Galery'}">{{ $t("nav.gallery.title1") }}</router-link></div>
            <div><router-link :to="{name: 'GaleryVideos'}">{{ $t("nav.gallery.title2") }}</router-link></div>
          </div>  
        </div>


        <router-link :to="{name: 'UsefullInformations'}">{{ $t("nav.usefulInfo") }}</router-link>
        <router-link :to="{name: 'Training'}">{{ $t("nav.training") }}</router-link>
        <!-- <router-link :to="{name: 'About'}">{{ $t("nav.contact") }}</router-link> -->
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showNav: false,
      galleryDropdown: false,
    };
  },
  methods:{
    goToHome(){
      return this.$router.push({name: 'Home'});
    },
    openMobileNav () {
      this.showNav = !this.showNav;
      this.galleryDropdown = false;
    }
  },
  watch: {
    $route() {
      this.showNav = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.nav-wrap {
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 5555;
  background: #7395ae url("../assets/navbackground.png");
  background-size: 100% 100%;
  color: #fff;
  height: 70px;

  #nav-mobile {
    height: 100vh;
    a {
      color: #fff;
      display: inline-block;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 34px;
      letter-spacing: 0em;
      text-align: center;
      transition: 0.3s;
      &:hover {
        color: #fff;
        transform: scale(1.1);
      }
    }
  }
  .burger {
    display: block;
    padding: 0 25px 0 5px;
    cursor: pointer;
    color: #fff;
    div {
      width: 25px;
      height: 4px;
      margin: 7px;
      border-radius: 3px;
    }
  }

  .line1,
  .line2,
  .line3 {
    width: 35px;
    background: #fff;
    margin: 6px 0;
    transition: 0.5s;
  }
  .change {
    padding: 0 25px 0 5px;
    cursor: pointer;
    color: #fff;
    div {
      width: 25px;
      height: 4px;
      margin: 7px;
      border-radius: 3px;
    }
    .line1 {
      -webkit-transform: rotate(-45deg) translate(-9px, 6px);
      transform: rotate(-45deg) translate(-9px, 6px);
    }
    .line2 {
      opacity: 0;
    }
    .line3 {
      -webkit-transform: rotate(45deg) translate(-8px, -8px);
      transform: rotate(45deg) translate(-8px, -8px);
    }
  }
}

#nav-mobile {
  padding: 0;
  position: fixed;
  top: 70px;
  width: 100%;
  background: #7395ae url("../assets/navbackground.png");
  background-size: 100% 100%;
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  font-weight: 700;
  z-index: 1000;
  p {
    color: #fff;
    font-weight: 400;
    text-align: center;
  }
  i {
    display: inline-block;
    margin: 0 10px 0 0;
  }
  .dropdown-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    a {
      font-size: 12px;
    }
  }
  a {
    font-size: 13px;
  }
}

#mobile-router-link-container {
  display: flex;
  // height: 95vh;
  height: 50vh;
  align-items: center;
  justify-content: space-evenly;
  // justify-content: center;
  flex-direction: column;
  .router-link-exact-active {
    color: rgb(202, 241, 248) !important;
  }
  > div > div {
    padding: 5px;
    border-radius: 3px;
    background: #7395ae63;
  }
}

.fa-users {
  display: inline-block;
  margin: 0 5px 0 0;
}
</style>
