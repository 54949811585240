{
  "nav": {
    "homepage": "Naslovna",
    "aboutUs": "O Theratogs-u",
    "ourPartners": "O Theratogs-u",
    "products": "Proizvodi",
    "productUsage": "Primena",
    "usefulInfo": "Korisne informacije",
    "training": "Obuke",
    "gallery": {
      "title": "Galerija",
      "title1": "Slike pre i posle upotrebe",
      "title2": "Video snimci"
    },
    "contact": "Kontakt",
    "products111111111111111": "Theratogs proizvodi",
    "productUsage111111111111111": "Primena u Heurorehabilitaciji",
    "usefulInfo111111111111111": "Korisne informacije",
    "training111111111111111": "Theratogs obuke",
    "gallery111111111111111": "Galerija",
    "contact111111111111111": "Kontakt"
  },
  "home": {
    "title": "Terapija koja se oblači",
    "list": {
      "title": "TheraTogs je meka ortoza u obliku odela sa dodatkom korektivnih elastičnih traka koje se postavljaju preko odela. Odelo može biti efikasna pomoć osobama koje pate od motornih i senzornih poremećaja, kao na primer:",
      "list1": "deci sa cerebralnom paralizom",
      "list2": "širokom spektru razvojnih problema kod dece",
      "list3": "kod stanja nakon moždanog udara",
      "list4": "kod oštećenja moždanih struktura usled povrede ili nakon operacije."
    },
    "firstParagraph": "TheraTogs  mogu koristiti i osobe  sa mišićno-skeletnim problemima vezanim za držanje tela, ravnotežom ili tegobama prilikom pokreta.",
    "secondParagraph": "Sačuvajte ruke terapeuta na sebi noseći Theratogs"
  },
  "about": {
    "title": "TheraTogs  je terapijski sistem u obliku odela",
    "firstParagraph": "Ideja o TheraTogs terapijskom odelu rođena je u Americi zahvaljujući iskustvu, znanju i istraživanju stručnjaka u oblasti neuromotorne rehabilitacije. To je meka ortoza u obliku odela sa dodatkom korektivnih elastičnih traka koje se postavljaju preko odela.",
    "secondParagraph": "Primena odela pokazuje rezultate kod dece sa razvojim problemima, kod osoba sa senzomotornim poremećajima, kao i kod osoba  sa mišićno-skeletnim problemima vezanim za držanje tela, ravnotežu ili teškoće prilikom pokreta.",
    "list": {
      "title": "Primenom TheraTogs odela mogu se očekivati poboljšanja koja se ogledaju u sledećem:",
      "list1": "boljem osećaju sopstevnog tela, razvijenijoj svesti o njegovom položaju u prostoru",
      "list2": "boljem doživljaju sopstvenih pokreta",
      "list3": "ispravnijem držanju tela",
      "list4": "poboljšanoj stabilnosti trupa",
      "list5": "lakšem izvođenju i većoj preciznosti pokreta",
      "list6": "stabilnosti zglobova",
      "list7": "boljem telesnom poravnanju* na svim nivoima. "
    }
  },
  "about1": {
    "title": "Indikacije za primenu odela",
    "list": {
      "title": "Cerebralna paraliza je stanje u kojem se najčešće primenjuje TheraTogs odelo. Međutim, širok spektar neuromotornih i posturalnih problema sa hipotonijom i mišićnom slabosti, kao i veći broj razvojnih problema kod dece mogu biti indikacija za upotrebu odela:",
      "list1":"Cerebelarna ataksija",
      "list2":"Problemi iz spektra autizma (ASD)",
      "list3":"Problemi deficita pažnje (ADHD)",
      "list4":"Cerebralna paraliza (mešovita)",
      "list5":"Ataksična CP",
      "list6":"Atetoidna CP",
      "list7":"Diskinetička CP",
      "list8":"Hemiplegija",
      "list9":"Distonija",
      "list10":"Hipotonija",
      "list11":"Usporen motorni razvoj",
      "list12":"Povrede mozga",
      "list13":"Daunov sindrom",
      "list14":"Spina Bifida",
      "list15":"Stanje nakon mozdanog udara",
      "list16":"Povrede mozga (TBI)",
      "list17":"Problem posturalne kontrole",
      "list18":"Kifoza ",
      "list19":"Lordoza",
      "list20":"Inverzija ili everzija stopala"
    }
  },
  "about2": {
    "title": "TheraTogs kao vaša druga koža",
    "firstParagraph": "TheraTogs ortoza izgleda kao kombinezon koji se sastoji od gornjeg dela (Tanktop) i bermuda (Hipster). Odeđeni modeli  imaju i dodatke u vidu manžetni za donje i gornje ekstremitete.  OrtozaTheraTogs obično se nosi preko donjeg veša, a ispod garedrobe, kao „pododelo“,  te u svakodnevnoj upotrebi ne mora biti vidljiva.",
    "secondParagraph": "TheraTogs je projektovan i fukncioniše kao vaša \"druga koža\". Odelo je bez lateksa, optimalne prozračnosti i maksimalne udobnosti. Tkanina ortoze GoldTone je sastavljena od dva sloja: prvi se sastoji od bele penaste obloge odela poliuretana na vodenoj bazi koji dolazi u kontakt sa kožom, dok je drugi sloj tkanina otporne spoljne površine namenjena za pričvršćivanje traka za potporu i korekciju."
  },
  "about3": {
    "title": "Gold Tone tehnologija",
    "firstParagraph": "Gold Tone tkanina pokazuje izraženu elastičnost pri horizontalnom istezanju, što obezbeđuje dobro prijanjanje i daje efekat blage kompresije.",
    "secondParagraph": "Verikalna vlakna tkanine pokazuju veći otpor na istezanje, što pri povlačenju tkanine nadole simulira delovanje gravitacione sile („prizemljuje“).",
    "thirdParagraph": "Beli penasti unutrašnji sloj tkanine, zbog tehnološki specifično obrađene strukture, stapa se sa površinom kože i deluje preko nje na fasciju (penasti sloj „grabi“ kožu tako da pri pokretu tangencijalno povlači i slojeve ispod nje). ",
    "fourthParagraph": "Površinski sloj tkanine  ima osobinu Velcro materijala pa se na njega mogu postavljajati „čičak“ stikeri za fiksaciju traka i tako ostati stabilni u tački fiksacije pri pokretu telu.",
    "fifthParagraph": "Tkanina ima sposobnost da se vrati u početno stanje nekoliko sati nakon upotrebe („rebound efekat“)."
  },
  "about4": {
    "title": "TheraTogs proizvodi",
    "firstParagraph": "Porodica TheraTogs proizvoda sadrži više formi odela namenjenih za sve uzraste, od novorođenih beba do odraslih osoba. Svaka vrsta proizvoda optimalno će odgovarati određenoj vrsti ili spektru senzomotornih problema. Savete u vezi sa odabirom i primenom odela možete dobiti od naših stručnjaka.",
    "secondParagraphTitle": "*Pojam telesno poravnanje",
    "secondParagraph": " odnosi se na položaj glave, kičmenog stuba, ramenog, karličnog pojasa i ekstremiteta, njihov skladan međusobni odnos koji omogućava optimalnu telesnu fukcionalnost."
  },
  "usage": {
    "p1": {
      "title": "Primena TheraTogs odela u neurorehabilitaciji",
      "firstParagraph1": "TheraTogs ",
      "firstParagraph2": "nije terapijska tehnika",
      "firstParagraph3": ". To je alat koji pruža lekaru ili terapeutu mogućnost da na jednostavan i efikasan način primenjuje postojeće priznate koncepte u neurološkoj rehabilitaciji i podršku motornom učenju.",
      "secondParagraph": "TheraTogs se efikasno primenjuje uz većinu neurorehabilitacionih tretmana: neurorazvojni tretman (NDT) Bobath, senzornu integraciju, tehniku proprioceptivne neuromuskulatne fasilitacije (PNF) i ABR i BDA tehniku i druge tretmane. Otroza u potpunosti korespondira za svim principima humane biomehanike i kinezilologije.",
      "thirdParagraph": "TheraTogs odelo u primeni ima i određena ograničenja. Ona se odnose na realan opseg korekcija, što je uglavnom limitirano korekcijama koje je moguće postići manuelno tokom opservacije ili tretmana pacijenta. Odelo ne može korigovati postojeće kontrakture i trajno narušenu strukturu."
    },
    "titleP3": "Rezultati koji se mogu očekivati nošenjem odela",
    "p3": {
      "title": "Proprioceptivno delovanje",
      "firstParagraph": "Samo odelo (TankTop i Hipster) bez postavke traka za korekciju pruža nežnu, ali moćnu potporu trupu i ima proprioceptivno dejstvo* zahvaljujući  tehnologiji GoldTone tkanine od koje je sačinjeno. Ovo dejstvo posebno je značajno za osobe sa senzornim problemima i za decu sa problemima iz spektra autizma."
    },
    "p4": {
      "title": "Korekcija držanja tela i korekcija pokreta",
      "firstParagraph": "Ukoliko je potrebno postići korekciju u držanju tela ili izvođenju pokreta, preko odela se postavljaju elastične trake koje su sastavni deo ThetaTogs kompleta. Postavke se kreiraju za svakog pacijenta posebno. Trake mogu biti različite čvrstine, elastičnosti i oblika i biraju se u zavisnosti od  željenog cilja, konstitucije pacijenta i drugih parametara.",
      "list": {
        "title": "Sledeći efekti se mogu očekivati postavkom traka za korekciju:",
        "list1": "potpora i podsticaj aktivnosti slabih, izduženih hipotoničnih mišića",
        "list2": "korekcija kifoze",
        "list3": "korekcija izražene lumbalne lordoze",
        "list4": "korekcija zaravnjenog slabinskog dela kičme",
        "list5": "olakšano ispravljanje gornjeg dela trupa i korekcija povijenih ramena",
        "list6": "korekcija problema “krilatih lopatica”",
        "list7": "potpora slabim mišićima prednjenjeg trbušog zida čime se postiže bolje držanje trupa",
        "list8": "smanjen disbalans karlice (prekomerna inklinacija i rotacija karlice)",
        "list9": "pomoć prilikom pokreta opružanja u zglobu kuka (korekcija u stajaćem položaju)",
        "list10": "bočna potpora zglobu kuka i olakšana bočna raspodela oslonca u stajaćem položaju",
        "list11": "potpora slabim mišićima odmicačima kuka (problem makazastog hoda)",
        "list12": "korekcija unutrašnje ili spolajšnje rotacije ekstremiteta",
        "list13": "korekcija prekomerne ekstenzije kolena (rekurvatum)",
        "list14": "korekcija prekomerne unutrašnje ili spoljašnje rotacije noge i stopala",
        "list15": "niz drugih korekcija definisanih u smernicama proizvođača ili baziranih na iskustvu vašeg lekara ili terapeuta."
      }
    },
    "p5": {
      "firstParagraph1": "*Pojam propriocepcija ",
      "firstParagraph2": "odnosi se na čulo kojim naše telo oseća samo sebe. To podrazumeva osećaj sopstvenog tela i njegovog položaja u prostoru kako prilikom stajanja (ležanja, sedenja), tako i u pokretu. Pojam propriocepcija koristi se i da opiše kako osećamo međusobni odnos delova našeg tela. Propriocepcija je bitna za razvoj telesne šeme i osnova je razvoja motorne kontrole. Ona obezbeđuje planiranje i tačnost izvođenja pokreta I doprinosi razvoju senzomotorike."
    }
    
  }
}





    


