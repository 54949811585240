import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/o-nama',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/primena',
    name: 'ProductUsage',
    component: () => import(/* webpackChunkName: "ProductUsage" */ '../views/ProductUsage.vue')
  },
  {
    path: '/korisne-informacije',
    name: 'UsefullInformations',
    component: () => import(/* webpackChunkName: "UsefullInformations" */ '../views/UsefullInformations.vue')
  },
  {
    path: '/obuke',
    name: 'Training',
    component: () => import(/* webpackChunkName: "Training" */ '../views/Training.vue')
  },
  {
    path: '/proizvodi',
    name: 'Products',
    component: () => import(/* webpackChunkName: "Products" */ '../views/Products.vue')
  },
  {
    path: '/proizvodi/:id',
    name: 'ProductCardDetails',
    props: true,
    component: () => import(/* webpackChunkName: "ProductCardDetails" */ '../components/ProductCardDetails.vue')
  },
  {
    path: '/galerija-slike',
    name: 'Galery',
    component: () => import(/* webpackChunkName: "Galery" */ '../views/Galery.vue')
  },
  {
    path: '/galerija-video-snimci',
    name: 'GaleryVideos',
    component: () => import(/* webpackChunkName: "GaleryVideos" */ '../views/GaleryVideos.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
