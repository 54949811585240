<template>
  <div class="nav-wrap fade-in-top">
    <div @click="goToHome" class="logo-img">
      <img src="../assets/logo.png" alt="logo" />
    </div>
    <nav class="nav-web">
      <div><router-link :to="{name: 'Home'}">{{ $t("nav.homepage") }}</router-link></div>
      <div><router-link :to="{name: 'About'}">{{ $t("nav.aboutUs") }}</router-link></div>
      <div><router-link :to="{name: 'Products'}">{{ $t("nav.products") }} <i class="fas fa-chevron-down"></i></router-link>
        <div class="dropdown fade-in-top">
          <span v-for="product in products" :key="product.id">
            <router-link :to="{name: 'ProductCardDetails', params:{id: product.id}}"> {{ product.name }} </router-link>
          </span>
        </div>
      </div>
      <div><router-link :to="{name: 'ProductUsage'}">{{ $t("nav.productUsage") }}</router-link></div>
      <div>
        <a>{{ $t("nav.gallery.title") }}
          <i class="fas fa-chevron-down"></i>
        </a>
        <div class="dropdown fade-in-top">
          <span><router-link :to="{name: 'Galery'}">{{ $t("nav.gallery.title1") }}</router-link></span>
          <span><router-link :to="{name: 'GaleryVideos'}">{{ $t("nav.gallery.title2") }}</router-link></span>
        </div>  
      </div>
      <div>
        <router-link :to="{name: 'UsefullInformations'}">{{ $t("nav.usefulInfo") }}  
          <!-- <i class="fas fa-chevron-down"></i> -->
        </router-link>
        <!-- <div class="dropdown fade-in-top">
          <router-link :to="{name: 'UsefullInformations'}">{{ $t("nav.usefulInfo") }}</router-link>
          <router-link :to="{name: 'UsefullInformations'}">{{ $t("nav.usefulInfo") }}</router-link>
          <router-link :to="{name: 'UsefullInformations'}">{{ $t("nav.usefulInfo") }}</router-link>
          <router-link :to="{name: 'UsefullInformations'}">{{ $t("nav.usefulInfo") }}</router-link>
          <router-link :to="{name: 'UsefullInformations'}">{{ $t("nav.usefulInfo") }}</router-link>
          <router-link :to="{name: 'UsefullInformations'}">{{ $t("nav.usefulInfo") }}</router-link>
          <router-link :to="{name: 'UsefullInformations'}">{{ $t("nav.usefulInfo") }}</router-link>
        </div>   -->
      </div>
      <div><router-link :to="{name: 'Training'}">{{ $t("nav.training") }}</router-link></div>
      <!-- <div><router-link :to="{name: 'Galery'}">{{ $t("nav.gallery") }}</router-link></div> -->
      <!-- <div><router-link :to="{name: 'About'}">{{ $t("nav.contact") }}</router-link></div> -->
    </nav>
  </div>
</template>

<script>
export default {
  computed:{
    products () {
      return this.$store.state.products;
    }
  },
  methods:{
    goToHome(){
      return this.$router.push({name: 'Home'});
    }
  }
};
</script>

<style lang="scss" scoped>
.nav-wrap {
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 5555;
  background: #7395ae url("../assets/navbackground.png");
  background-size: 100% 100%;
  color: #fff;
  height: 70px;
  text-transform: uppercase;
  box-shadow: 1px 1px 5px rgba(22, 22, 22, 0.53);

  .nav-web {
    display: flex;
    margin: 0 40px 0 0;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    transition: 0.3s;
    height: 100%;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      a {
        color: #fff;
        padding: 10px;
        cursor: pointer;
        transition: .5s;
        &:hover {
          color: #fff;
          transform: scale(1.1);
        }
      }
      .dropdown {
        display: none;
        position: absolute;
        left: 0;
        min-width: 170px;
        padding: 15px;
        font-size: .9em;
        transition: .5s;

        a {
          margin: 15px 0;
        }
      }
      &:hover .dropdown{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        top: 35px;
        z-index: 22;
        background: #7395ae;
        text-transform: uppercase;
        box-shadow: 1px 1px 5px rgba(22, 22, 22, 0.53);
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
          height: 100%;
          padding: 5px;
          a {
            padding: 0;
            margin: 5px;
            width: 90%;
            height: 100%;
          }
        }
      }
    }
  }
  .router-link-active {
    color: rgb(175, 237, 248) !important;
  }
}
</style>
